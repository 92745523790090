import React, { useEffect, useState } from 'react';
import * as styles from '../styles/paymentResult.module.css';
import Layout from '../components/layout';
const PaymentResult = () => {
  const [result, setResult] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('https://75f7w41ox2.execute-api.us-east-2.amazonaws.com/stage/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          session_id: sessionId
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const portalUrl = data.url; // Assuming the API response contains the URL in a field named 'url'

      // Open the URL in a new window
      window.open(portalUrl, '_blank');
      window.location.href = '/career/';

    } catch (error) {
      console.error('Error creating portal session:', error);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const success = queryParams.get('success');
    const canceled = queryParams.get('canceled');
    const sessionId = queryParams.get('session_id');

    setSessionId(sessionId);

    if (canceled) {
      setResult('canceled');
    } else if (!sessionId) {
      setResult('noSession');
    } else if (success) {
      setResult('success');
    }
  }, []);


  if (result === 'noSession') {
    window.location.href = '/career/'; 
    return <div></div>; // Blank page if session_id is missing
  }

  return (
    <Layout>
    <section className={styles.paymentResult}>
      {result === 'success' && (
        <div className={styles.successMessage}>
          <h1>Payment Successful!</h1>
          <p>Thank you for your purchase.</p>
          <form onSubmit={handleFormSubmit}>
            <input
            type="hidden"
            id="session-id"
            name="session_id"
            value={sessionId}
            />
            <button style={{
              padding: '10px 20px',
              fontSize: '16px',
              color: 'black',
              backgroundColor: 'lightblue',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s, color 0.3s'
            }} id="checkout-and-portal-button" type="submit" onMouseOver={(e) => {
              e.target.style.backgroundColor = 'lightblue';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = 'lightblue';
            }}>
            Manage your billing information
            </button>
       
        </form>
        </div>
      )}
      {result === 'canceled' && (
        <div className={styles.canceledMessage}>
          <h1>Payment Canceled</h1>
          <p>Your payment was not completed. Please try again.</p>
        </div>
      )}
    </section>
    </Layout>
  );
};

export default PaymentResult;
