// src/components/Footer.js
import React from 'react';
import { Link } from 'gatsby';


const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', textAlign: 'center', padding: '20px 0' }}>
    <div>
      <Link to="/" style={{ margin: '0 10px' }}>Home</Link>
      <Link to="/about-us/" style={{ margin: '0 10px' }}>About us</Link>
      <Link to="/career/pathways/">Pathways</Link>
      <Link to="/career/resume-builder-ai/" style={{ margin: '0 10px' }}>Resume Builder AI</Link>
      <Link to="/career/cover-letter-ai/" style={{ margin: '0 10px' }}>Cover Letter AI</Link>
      <Link to="/career/job-application-tracker/" style={{ margin: '0 10px' }}>Job Application Tracker</Link>
      <Link to="/career/job-analysis-ai/" style={{ margin: '0 10px' }}>Job Analysis</Link>
      <Link to="/career/resume-analysis-ai/" style={{ margin: '0 10px' }}>Resume Scan & Review AI</Link>
      <Link to="/blog" style={{ margin: '0 10px' }}>Blog</Link>
      <Link to="/contact" style={{ margin: '0 10px' }}>Contact Us</Link>
      <Link to="/privacy" style={{ margin: '0 10px' }}>Privacy Policy</Link>
    </div>
    <p style={{ margin: 0 }}>© {new Date().getFullYear()} STAIRAZ - All Rights Reserved.</p>
  </footer>
  );
};

export default Footer;
