import React, { useState, useEffect } from 'react';
import { Link} from 'gatsby';
import "../styles/navBar.css"
import { generateClient } from 'aws-amplify/api';
import { fetchUserAttributes,getCurrentUser} from "@aws-amplify/auth";
import { StaticImage } from "gatsby-plugin-image"
import { FaBars } from "react-icons/fa6";
import { useTheme } from '../context/ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa';

const NavBar = ({notification}) => {
  const [user, setUser] = useState(null);
  const { isDarkMode, toggleMode } = useTheme();
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sourceParam = params.get('source');
    if (sourceParam) {
      localStorage.setItem('source', sourceParam);
    }
  }, []);



  const [isFetchCurrentUser,setIsFetchCurrentUser] = useState(false) 
  const [isDropdownOpenService, setIsDropdownOpenService] = useState(false);
  const [isDropdownOpenExample, setIsDropdownOpenExample] = useState(false);
  const client = generateClient();
  const toggleDropdownService = () => {
    setIsDropdownOpenService(!isDropdownOpenService);
    setIsDropdownOpenExample(false);
  };
  const toggleDropdownExample = () => {
    setIsDropdownOpenExample(!isDropdownOpenExample);
    setIsDropdownOpenService(false);
  };

  

  async function currentAuthenticatedUser() {
    try {
      const userData = await fetchUserAttributes();
      const session = await getCurrentUser();
      localStorage.setItem('user', JSON.stringify(userData))
      const username = session.username;
      const email = userData.email;
      const firstName = userData.given_name;
      const lastName = userData.family_name;
      // addUserProfile(username, email,firstName,lastName);

    } catch (err) {
      localStorage.removeItem('user')
      console.error(err)

    }
    setIsFetchCurrentUser(true)
  }
  
  

  useEffect(() => {
    // Update state with user data from localStorage
    const userData = JSON.parse(localStorage.getItem('user'));
  
    if (!userData) {
      currentAuthenticatedUser()
    }
    
    
  }, []);

  useEffect(() => {
    // Update state with user data from localStorage
    
    const userData = JSON.parse(localStorage.getItem('user'));
      
    setUser(userData)
  }, [isFetchCurrentUser]);
  
  const handleUserAuthClick = () => {
    // Save the current path before redirecting to sign-in page
    if (!user) {
    localStorage.setItem('preAuthPath', window.location.pathname);
    
    }
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

  };

  return (

    <nav className="nav-bar-with-notify-container">
    {notification && (
    <div className="notification-bar">
      {notification}
    </div>
    )}
    <div className="nav-bar-container">
      <div className="header-container">
        <Link to = '/career/' className="company-logo-name-container">
        
            <StaticImage
              src='../images/logo.png'
              alt="Company Logo"
                className="company-logo"
                width={60}
                height={60}
                placeholder="blurred"
            />
       
          <div className="company-name">
            <h1>STAIRAZ</h1>
          </div>
        </Link>

        <div className="menu-toggle" onClick={toggleMenu}>
          <FaBars /> {/* Assuming you're using FontAwesome icons */}
        </div>
      </div>
      <div className={`menu-login ${isMenuOpen ? 'open' : ''}`}>
        <ul className="menu">
          <li>
            <Link to="/" className="menu-item">Home</Link>
          </li>
     
      
            <li>
            <Link to="/payment/" className="menu-item">Plan</Link>
            </li>
      
            <li>
            <Link to="/contact/" className="menu-item">Contact US</Link>
            </li>
        </ul>
       
        {/* <a 
        href="/dashboard/" 
        className={`user-profile ${user ? 'logged-in' : 'logged-out'}`} 
        onClick={handleUserAuthClick}
        target="_blank"
      >
        Dashboard
      </a> */}
      {/* <div className={`switch-button ${isDarkMode ? 'dark' : 'light'}`} onClick={toggleMode}>
      <div className="switch-icon sun-icon">
        <FaSun />
      </div>
      <div className="switch-icon moon-icon">
        <FaMoon />
      </div>
      <div className="switch-circle"></div>
    </div> */}
      </div>
      </div>
    </nav>

  );
  
};

export default NavBar;
