import React,{useEffect} from 'react';
import NavBar from './navBar.js';

import { useTheme } from '../context/ThemeContext';
import '../styles/layout.css'
import Footer from './footer';

const Layout = ({ children,notification }) => {
  const { isDarkMode } = useTheme();


    return (
    
    <div className={`background ${isDarkMode ? '' : 'lightMode'}`}>
    
      
        <div>
        <NavBar notification={notification}/>
        </div>
        <div className="main-content-container">
          
          {children}
          <Footer />
        </div>
     

       
    </div>
    
    );
  };
export default Layout;
